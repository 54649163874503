import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { Path, getPath } from 'shared/path';
import { ReactComponent as Logo } from 'shared/assets/logo/logo.svg';
import { ReactComponent as Chevron } from 'shared/assets/icons/chevron.svg';
import { REGISTER_URL } from 'shared/settings';
import Styles from './header.module.css';


const Tailwind = {
  Link: 'flex text-purple-800 text-xl px-5 md:px-3 py-3 hover:text-purple-700 items-center',
  SubmenuItem: 'flex text-purple-800 text-xl px-5 md:px-3 py-3 hover:text-purple-700 block',
};

function HeaderItem({ children, ...props }: LinkProps) {
  return (
    <li className='flex items-center'>
      <Link className={Tailwind.Link} {...props}>
        {children}
      </Link>
    </li>
  );
}

function SubmenuItem({ children, ...props }: LinkProps) {

  return (
    <li className='ml-4 md:ml-0'>
      <Link className={Tailwind.SubmenuItem} {...props}>
        {children}
      </Link>
    </li>
  );
}

type HeaderSubmenuProps = {
  to: string;
  content: React.ReactNode;
  children: React.ReactNode;
};

function HeaderSubmenu({ to, content, children }: HeaderSubmenuProps) {
  const [expanded, setExpanded] = React.useState(false);
  const submenu = React.useRef<HTMLUListElement>(null);
  const fading = React.useRef<NodeJS.Timeout | null>(null);

  const toggleExpanded = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onMouseEnter = React.useCallback(() => {
    submenu.current?.classList.remove(Styles.fading);
    if (fading.current != null) {
      clearTimeout(fading.current);
      fading.current = null;
    }
  }, []);

  const onMouseLeave = React.useCallback(() => {
    // This should do nothing. You can't leave twice in a row, but paranoia!
    if (fading.current != null) {
      clearTimeout(fading.current);
      fading.current = null;
    }

    if (submenu.current == null) {
      return;
    }
    submenu.current.classList.add(Styles.fading);

    // The timer here should match
    fading.current = setTimeout(() => {
      submenu.current?.classList.remove(Styles.fading);
    }, 250);
  }, []);

  return (
    <li className={Styles.anchor} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className='flex justify-between'>
        <Link to={to} className={Tailwind.Link}>
          {content}
          <Chevron className='hidden md:block text-gray-500 ml-2' height='12px' width='12px'/>
        </Link>
        <button
          className='text-purple-800 px-4 py-3 hover:text-purple-700 md:hidden'
          type='button'
          onClick={toggleExpanded}
        >
          <Chevron className={classNames(Styles.chevron, expanded && Styles.expanded)} height='12px' width='12px'/>
        </button>
      </div>
      <ul className={classNames(expanded || Styles.hidden, Styles.submenu)} ref={submenu}>
        {children}
      </ul>
    </li>
  );
}

export function Header() {
  const [hidden, setHidden] = React.useState(true);

  const toggleMenu = React.useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);

  return (
    <div className='sticky top-0 z-10 border-b-[1px] border-b-gray-300 bg-white'>
      <div className='max-w-screen-xl mx-auto flex flex-wrap items-stretch'>
        <Link to={getPath({ kind: Path.Home })} className='flex items-center px-2 ml-4 text-purple-600 hover:text-purple-400'>
          <Logo className='rounded-full w-[38px] h-[38px] mb-[4px] mr-2'/>
          <div className='font-bold text-3xl py-4'>
            Mechaform
          </div>
        </Link>
        <button
          type='button'
          onClick={toggleMenu}
          className='ml-auto mr-4 inline-flex self-center items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none hover:bg-gray-100 ring-gray-200 focus:ring-2 hover:ring-2' aria-controls='navbar-default' aria-expanded='false'
        >
          <span className='sr-only'>Open main menu</span>
          <svg className='w-5 h-5' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 17 14'>
            <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M1 1h15M1 7h15M1 13h15'/>
          </svg>
        </button>
        <ul className={classNames(hidden && 'hidden', 'flex-auto w-full px-4 md:px-0 md:flex md:items-stretch md:justify-end md:w-auto md:gap-3 md:mx-6')}>
          <HeaderSubmenu to={getPath({ kind: Path.Concepts })} content={<>Concepts</>}>
            <SubmenuItem to={getPath({ kind: Path.Concepts })}>
              Overview
            </SubmenuItem>
            <SubmenuItem to={getPath({ kind: Path.ConceptsDefinitions })}>
              Definitions
            </SubmenuItem>
            <SubmenuItem to={getPath({ kind: Path.ConceptsForms })}>
              Forms
            </SubmenuItem>
            <SubmenuItem to={getPath({ kind: Path.ConceptsMechanisms })}>
              Mechanisms
            </SubmenuItem>
          </HeaderSubmenu>
          <HeaderItem to={getPath({ kind: Path.Pricing })}>Pricing</HeaderItem>
          <HeaderItem to={getPath({ kind: Path.About })}>About</HeaderItem>
          <div className='hidden md:block border-r border-gray-300 my-3'/>
          <div className='flex items-center'>
            <Link className={classNames(Tailwind.Link, 'self-center')} to={REGISTER_URL} data-track='header-signup'>
              Sign Up
            </Link>
          </div>
        </ul>
      </div>
    </div>
  );
}
