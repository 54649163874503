import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { Path, getPath } from 'shared/path';
import { HomeComponent } from 'pages/home/home';
import { PricingComponent } from 'pages/pricing/pricing';
import { AboutComponent } from 'pages/about/about';
import { ConceptsOverviewComponent } from 'pages/concepts/overview';
import { ConceptsDefinitionsComponent } from 'pages/concepts/definitions';
import { ConceptsFormsComponent } from 'pages/concepts/forms';
import { ConceptsMechanismsComponent } from 'pages/concepts/mechanisms';
import { useAnalyticsTracker } from 'shared/analytics';

function ActualMain() {
  const { pathname } = useLocation();
  const initialPath = React.useRef<string | null>(null);

  // Automatically scrolls to top whenever pathname changes
  React.useEffect(() => {
    if (initialPath.current == null) {
      // Don't run on initial mount
      initialPath.current = pathname;
      return;
    } else if (initialPath.current === pathname) {
      // Don't run on second mount
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  useAnalyticsTracker();

  return (
    <Routes>
      <Route path={getPath({ kind: Path.Home })} element={<HomeComponent/>}/>
      <Route path={getPath({ kind: Path.Pricing })} element={<PricingComponent/>}/>
      <Route path={getPath({ kind: Path.About })} element={<AboutComponent/>}/>
      <Route path={getPath({ kind: Path.Concepts })} element={<ConceptsOverviewComponent/>}/>
      <Route path={getPath({ kind: Path.ConceptsDefinitions })} element={<ConceptsDefinitionsComponent/>}/>
      <Route path={getPath({ kind: Path.ConceptsForms })} element={<ConceptsFormsComponent/>}/>
      <Route path={getPath({ kind: Path.ConceptsMechanisms })} element={<ConceptsMechanismsComponent/>}/>
    </Routes>
  );
}

function Main() {
  return (
    <BrowserRouter>
      <ActualMain/>
    </BrowserRouter>
  );
}

export default Main;
