import React from 'react';
import classNames from 'classnames';
import { HexTiles } from 'shared/ui/hex_tiles/hex_tiles';
import { Path, getPath } from 'shared/path';
import { REGISTER_URL } from 'shared/settings';
import Styles from './hero.module.css';

const Tailwind = {
  Button: 'font-medium text-md text-center rounded-full px-5 py-2.5 select-none',
  BlueButton: 'text-white bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700',
  WhiteButton: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600',
};


export function HeroComponent() {
  return (
    <div className={Styles.hero}>
      <HexTiles/>
      <div className='max-w-screen-md mx-auto text-center h-[85vh] px-4 flex flex-col justify-center'>
        <div className='text-5xl hover:drop-shadow-lg'>
          Automate Everything
        </div>
        <div className='max-w-screen-md mx-auto mt-5 text-xl'>
          Transform the way your team collaborates.
          No code required.
        </div>
        <div className='flex justify-center mt-5 gap-8'>
          <a href={REGISTER_URL} className={classNames(Tailwind.Button, Tailwind.BlueButton)} data-track='hero-start'>
            Start Building
          </a>
          <a href={getPath({ kind: Path.Concepts })} className={classNames(Tailwind.Button, Tailwind.WhiteButton)} data-track='hero-learn'>
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}
