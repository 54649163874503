import React from 'react';
import { Footer } from 'shared/ui/footer/footer';
import { Header } from 'shared/ui/header/header';
import HexStyles from 'shared/ui/hex_tiles/hex_tiles.module.css';
import classNames from 'classnames';
import { REGISTER_URL } from 'shared/settings';

function CallToAction() {
  return (
    <div className='max-w-screen-md mx-auto text-center pt-12 pb-8 px-4'>
      <div className='text-3xl'>
        Join us on this journey
      </div>
      <div className='flex justify-center mt-5 gap-8'>
        <a href={REGISTER_URL} className='font-medium text-md text-center rounded-full px-5 py-2.5 select-none text-white bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700' data-track='about-start'>
          Start Building
        </a>
      </div>
    </div>
  );
}


export const AboutComponent = () => {
  return (
    <div className={classNames('flex flex-col items-stretch min-h-full relative', HexStyles.hexfixed)}>
      <Header/>
      <div className='flex-1 max-w-screen-md mx-auto bg-white border-x border-[#5747be4b] px-10'>
        <h1 className='text-5xl text-center text-purple-600 flex justify-center items-center h-[75vh] leading-normal'>
          Empowering the world with&nbsp;computation
        </h1>
        <h1 className='text-4xl text-center mb-6'>
          About Us
        </h1>
        <p className='text-xl mb-3'>
          We&apos;re building the most flexible workflow platform in the world. We think that if we give people
          the right tools, we can empower them to create their own solutions.
        </p>
        <p className='text-xl mb-3'>
          We believe in letting our customers build for the long-term. The best systems can be maintained for years
          and adapt as requirements change. Sometimes this means more time setting up and we think that&apos;s okay.
          Templates can help set the initial direction, but every organization and every workflow is different.
        </p>
        <p className='text-xl mb-3'>
          Above all, we are firm believers in the power of computers to improve human lives. We are dedicated to
          to empowering our users, giving them the tools to create systems and streamline their tasks through automation,
          and letting them focus on the things that truly matter.
        </p>
        <CallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
