import anime from 'animejs';
import {
  AnimationScene,
  ExplainerBase,
  FancyTransitionKind,
  makeFancyTransition,
  makeHidden,
  nextAnimationFrame,
  overlayObject,
} from './explainer_utils';
import {
  DrawArrowAnimation,
  HideAnimation,
  RevealAnimation,
} from './animations';


export class ExplainerMechanism extends ExplainerBase {
  async _init(scene: AnimationScene) {
    const reviewForm = scene.reviewForm;
    const arrow: SVGSVGElement | null = scene.stageMechanism.querySelector('.arrow');
    const mech: SVGGeometryElement | null = scene.stageMechanism.querySelector('.mech');
    const email = scene.stageMechanism.querySelector('.email-act');
    const sms = scene.stageMechanism.querySelector('.sms-act');
    const browser = scene.stageMechanism.querySelector('.browser-act');
    const emailplus = scene.stageMechanism.querySelector('.email-plus');
    const smsplus = scene.stageMechanism.querySelector('.sms-plus');
    const browserplus = scene.stageMechanism.querySelector('.browser-plus');

    if (
      arrow == null ||
      mech == null ||
      email == null ||
      sms == null ||
      browser == null ||
      emailplus == null ||
      smsplus == null ||
      browserplus == null
    ) {
      return null;
    }

    const timeline = anime.timeline({
      easing: 'linear',
      complete: this.onAnimationComplete,
    });

    timeline.add(RevealAnimation(emailplus), 0);
    DrawArrowAnimation({ timeline, arrow });
    timeline.add(RevealAnimation(mech));
    timeline.add(RevealAnimation(reviewForm));
    const tEmail = timeline.duration;
    timeline.add(HideAnimation(emailplus), tEmail);
    timeline.add(RevealAnimation(smsplus), tEmail);
    timeline.add(RevealAnimation(email), tEmail);
    const tSMS = timeline.duration;
    timeline.add(HideAnimation(smsplus), tSMS);
    timeline.add(RevealAnimation(browserplus), tSMS);
    timeline.add(RevealAnimation(sms), tSMS);
    const tBrowser = timeline.duration;
    timeline.add(HideAnimation(browserplus), tBrowser);
    timeline.add(RevealAnimation(browser), tBrowser);

    return timeline;
  }


  async setStage(scene: AnimationScene) {
    makeHidden(scene.stageMechanism, '.review-form');

    await nextAnimationFrame();

    overlayObject(scene.stageMechanism, '.review-form', scene.reviewForm);

    const arrow = scene.stageMechanism.querySelector('.arrow');
    const arrowHead = scene.stageMechanism.querySelector('.arrow .path-head');
    const mech = scene.stageMechanism.querySelector('.mech');
    const email = scene.stageMechanism.querySelector('.email-act');
    const sms = scene.stageMechanism.querySelector('.sms-act');
    const browser = scene.stageMechanism.querySelector('.browser-act');
    const emailplus = scene.stageMechanism.querySelector('.email-plus');
    const smsplus = scene.stageMechanism.querySelector('.sms-plus');
    const browserplus = scene.stageMechanism.querySelector('.browser-plus');

    scene.stageMechanism.style.zIndex = '1';
    arrow?.setAttribute('opacity', '0');
    arrowHead?.setAttribute('opacity', '0');
    mech?.setAttribute('opacity', '0');
    email?.setAttribute('opacity', '0');
    sms?.setAttribute('opacity', '0');
    browser?.setAttribute('opacity', '0');
    emailplus?.setAttribute('opacity', '1');
    smsplus?.setAttribute('opacity', '0');
    browserplus?.setAttribute('opacity', '0');
  }

  async _setupForward(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    scene.reviewForm.setAttribute('opacity', '1');

    return makeFancyTransition([
      {
        kind: FancyTransitionKind.Translate,
        node: scene.reviewForm,
      },
    ]);
  }

  async _setupReverse(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    scene.reviewForm.setAttribute('opacity', '1');

    return makeFancyTransition([
      {
        kind: FancyTransitionKind.Translate,
        node: scene.reviewForm,
      },
    ]);
  }

  async _cleanup(_scene: AnimationScene) {
    // pass
  }
}
