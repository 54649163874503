import React from 'react';
import classNames from 'classnames';
import { Footer } from 'shared/ui/footer/footer';
import { Header } from 'shared/ui/header/header';
import { ReactComponent as Check } from 'shared/assets/icons/check.svg';
import { Link } from 'react-router-dom';
import HexStyles from 'shared/ui/hex_tiles/hex_tiles.module.css';
import { ConceptsCallToAction } from 'pages/concepts/concepts_base';
import { CONTACT_SALES_URL, REGISTER_URL } from 'shared/settings';
import Styles from './pricing.module.css';

type PricingPlan = {
  name: string;
  description: string;
  priceYear: string;
  priceMonth: string;
};

type PlanKey = 'Free' | 'Basic' | 'Enterprise';

const Plans: Record<PlanKey, PricingPlan> = {
  Free: {
    name: 'Free',
    description: 'For individuals starting out with Mechaform.',
    priceYear: '0',
    priceMonth: '0',
  },
  Basic: {
    name: 'Basic',
    description: 'For teams building systems to collaborate on shared workflows.',
    priceYear: '20',
    priceMonth: '24',
  },
  Enterprise: {
    name: 'Enterprise',
    description: 'For larger teams and departments who need a little more',
    priceYear: 'Custom Price',
    priceMonth: 'Custom',
  },
};


type PricingTableProps = {
  annual: boolean;
};

function PricingTable({ annual }: PricingTableProps) {
  return (
    <div className='flex flex-col items-center lg:flex-row lg:items-stretch gap-4'>
      <PricingCard
        plan={Plans.Free}
        pricing={<PriceFree/>}
        cta={<CallToAction to={REGISTER_URL} data-track='pricing-start'>Try it for free</CallToAction>}
      >
        <FeatureTitle>
          Free Includes:
        </FeatureTitle>
        <FeatureList>
          <FeatureItem>
            Definitions and Storage
          </FeatureItem>
          <FeatureItem>
            Visual Form Editor
          </FeatureItem>
          <FeatureItem>
            Mechanisms and Automation
          </FeatureItem>
          <FeaturesMobile>
            {/* Data Storage */}
            <FeatureTitle>
              Data Storage
            </FeatureTitle>
            <FeatureItem>
              3 Definitions
            </FeatureItem>
            <FeatureItem>
              500 objects per definition
            </FeatureItem>
            <FeatureItem>
              1GB of attachment storage
            </FeatureItem>
            <FeatureItem>
              Relationships
            </FeatureItem>
            <FeatureItem>
              Formulas
            </FeatureItem>

            {/* Forms */}
            <FeatureTitle>
              Forms
            </FeatureTitle>
            <FeatureItem>
              Unlimited Forms
            </FeatureItem>
            <FeatureItem>
              Basic Widgets
            </FeatureItem>
            <FeatureItem>
              Conditional Sections
            </FeatureItem>
            <FeatureItem>
              Computed Fields
            </FeatureItem>
            <FeatureItem>
              Hidden Fields
            </FeatureItem>
            <FeatureItem>
              Pre-filled Fields
            </FeatureItem>
            <FeatureItem>
              List Collection
            </FeatureItem>

            {/* Mechanisms and Automation */}
            <FeatureTitle>
              Mechanisms and Automation
            </FeatureTitle>
            <FeatureItem>
              10 Mechanisms
            </FeatureItem>
            <FeatureItem>
              500 Runs per month
            </FeatureItem>
            <FeatureItem>
              Form-Triggered Mechanisms
            </FeatureItem>

            {/* Workflow and Automation */}
            <FeatureTitle>
              Form Deployment
            </FeatureTitle>
            <FeatureItem>
              Standalone Pages
            </FeatureItem>
            <FeatureItem>
              HTML Embeds
            </FeatureItem>
            <FeatureItem>
              Internal Applications
            </FeatureItem>

            {/* Branding */}
            <FeatureTitle>
              Branding
            </FeatureTitle>
            <FeatureItem>
              Custom Styling
            </FeatureItem>
          </FeaturesMobile>
        </FeatureList>
      </PricingCard>
      <PricingCard
        plan={Plans.Basic}
        pricing={
          <PriceStandard
            priceMonth={Plans.Basic.priceMonth}
            priceYear={Plans.Basic.priceYear}
            annual={annual}
          />
        }
        cta={<CallToAction to={REGISTER_URL} data-track='pricing-start'>Try it for free</CallToAction>}
      >
        <FeatureTitle>
          Everything in Free, plus:
        </FeatureTitle>
        <FeatureList>
          <FeatureItem>
            50,000 objects per definition
          </FeatureItem>
          <FeatureItem>
            25,000 mechanism runs
          </FeatureItem>
          <FeatureItem>
            20GB of attachment storage
          </FeatureItem>
          <FeatureItem>
            Advanced form widgets
          </FeatureItem>
          <FeatureItem>
            Calendar, kanban, and map Collection Widgets
          </FeatureItem>

          <FeaturesMobile>
            {/* Data Storage */}
            <FeatureTitle>
              Data Storage
            </FeatureTitle>
            <FeatureItem>
              20 Definitions
            </FeatureItem>
            <FeatureItem>
              50,000 objects per definition
            </FeatureItem>
            <FeatureItem>
              20GB of attachment storage
            </FeatureItem>

            {/* Forms */}
            <FeatureTitle>
              Forms
            </FeatureTitle>
            <FeatureItem>
              Advanced form widgets
            </FeatureItem>
            <FeatureItem>
              Calendar, kanban, and map Collection Widgets
            </FeatureItem>
            <FeatureItem>
              Pivot Tables and Charts
            </FeatureItem>

            {/* Mechanisms and Automation */}
            <FeatureTitle>
              Mechanisms and Automation
            </FeatureTitle>
            <FeatureItem>
              Unlimited Mechanisms
            </FeatureItem>
            <FeatureItem>
              25,000 runs per month
            </FeatureItem>
          </FeaturesMobile>
        </FeatureList>
      </PricingCard>
      <PricingCard
        plan={Plans.Enterprise}
        pricing={null}
        cta={<CallToAction to={CONTACT_SALES_URL} data-track='pricing-contact'>Contact sales</CallToAction>}
      >
        <FeatureTitle>
          Everything in Basic, plus:
        </FeatureTitle>
        <FeatureList>
          <FeatureItem>
            Negotiable resource limits
          </FeatureItem>
          <FeatureItem>
            Definition table indexes
          </FeatureItem>
          <FeatureItem>
            Single Sign-On
          </FeatureItem>

          <FeaturesMobile>
            {/* Data Storage */}
            <FeatureTitle>
              Data Storage
            </FeatureTitle>
            <FeatureItem>
              Unlimited Definitions
            </FeatureItem>
            <FeatureItem>
              Negotiable objects per definition
            </FeatureItem>
            <FeatureItem>
              Negotiable attachment storage
            </FeatureItem>

            {/* Mechanisms and Automation */}
            <FeatureTitle>
              Mechanisms and Automation
            </FeatureTitle>
            <FeatureItem>
              Unlimited Mechanisms
            </FeatureItem>
            <FeatureItem>
              Negotiable runs per month
            </FeatureItem>
          </FeaturesMobile>
        </FeatureList>
      </PricingCard>
    </div>
  );
}

type PricingCardProps = {
  plan: PricingPlan;
  pricing: React.ReactNode;
  cta: React.ReactNode;
  children: React.ReactNode;
};

function PricingCard({ plan, pricing, cta, children }: PricingCardProps) {
  const [animated, setAnimated] = React.useState(false);
  const startAnimation = React.useCallback(() => {
    setAnimated(true);
  }, [animated]);

  return (
    <div
      className={classNames(
        Styles.card,
        animated && Styles.animated,
        'border border-gray-300 bg-white rounded-lg px-4 pt-5 pb-6 flex-1 w-[360px] lg:w-full max-w-full',
      )}
      onMouseOver={startAnimation}
    >
      <h3 className='text-xl font-bold mb-2'>
        {plan.name}
      </h3>
      <div className='text-sm mb-2 text-gray-600'>
        {plan.description}
      </div>
      <div className='flex flex-col justify-center mb-5'>
        {pricing}
      </div>
      <div>
        {cta}
      </div>
      <div className=''>
        {children}
      </div>
    </div>
  );
}

const Tailwind = {
  Description: '',
  PriceGroup: '',
  PriceDescription: '',
};


type PriceStandardProps = {
  priceMonth: string;
  priceYear: string;
  annual: boolean;
};

function PriceFree() {
  return (
    <div className={Tailwind.PriceGroup}>
      <div className='text-6xl'>
        Free
      </div>
    </div>
  );
}

function PriceStandard({ priceMonth, priceYear, annual }: PriceStandardProps) {
  return (
    <div className={Tailwind.PriceGroup}>
      <div className='flex'>
        <div className='text-6xl'>
          ${annual ? priceYear : priceMonth}
        </div>
        <div className='flex flex-col justify-end mb-1 ml-2 text-gray-600'>
          <div className=''>
            per seat/month
          </div>
          <div className=''>
            {annual ? 'billed annually' : 'billed monthly'}
          </div>
        </div>
      </div>
    </div>
  );
}

type CallToActionProps = {
  to: string;
  'data-track': string;
  children: React.ReactNode;
};

function CallToAction({ to, children, 'data-track': tracker }: CallToActionProps) {
  return (
    <Link to={to} className='block text-center bg-purple-500 hover:bg-purple-400 text-white text-lg font-bold rounded-md p-4 mb-5' data-track={tracker}>
      {children}
    </Link>
  );
}

type FeatureTitleProps = {
  children?: React.ReactNode;
};

function FeatureTitle({ children }: FeatureTitleProps) {
  return (
    <div className='text-lg text-bold mb-2'>
      {children}
    </div>
  );
}

type FeatureListProps = {
  children?: React.ReactNode;
};

function FeatureList({ children }: FeatureListProps) {
  return (
    <ul className=''>
      {children}
    </ul>
  );
}

type FeaturesMobileProps = {
  children?: React.ReactNode;
};

function FeaturesMobile({ children }: FeaturesMobileProps) {
  const [hidden, setHidden] = React.useState(true);

  const toggleHidden = React.useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);

  return (
    <div className='lg:hidden mt-4'>
      <button
        type='button'
        onClick={toggleHidden}
        className='text-gray-500 hover:text-gray-900'
      >
        {hidden ? 'Show Features' : 'Hide Features'}
      </button>
      <ul className={classNames(
        'mt-4',
        hidden && 'hidden',
      )}>
        {children}
      </ul>
    </div>
  );
}

type FeatureItemProps = {
  alt?: string;
  children?: React.ReactNode;
};

function FeatureItem({ alt, children }: FeatureItemProps) {
  return (
    <li className='flex items-center mb-2 pl-1' title={alt}>
      <Check className='h-[14px] w-[14px] mr-2 mb-[1px]'/>
      <span className='text-md font-light'>
        {children}
      </span>
    </li>
  );
}

function FeatureCheck() {
  return (
    <Check className='inline-block h-[14px] w-[14px] mr-2 mb-[1px]'/>
  );
}

type FeatureSectionProps = {
  children?: React.ReactNode;
};

function FeatureSection({ children }: FeatureSectionProps) {
  return (
    <div className='text-lg font-semibold mt-2 mb-[1px] px-2'>
      {children}
    </div>
  );
}

type FeatureRowProps = {
  children?: React.ReactNode;
};

function FeatureRow({ children }: FeatureRowProps) {
  return (
    <div className='flex flex-row hover:bg-[#5747BE20] px-4'>
      {children}
    </div>
  );
}


type FeatureCellProps = {
  first?: boolean;
  header?: boolean;
  children?: React.ReactNode;
};

function FeatureCell({ first, header, children }: FeatureCellProps) {
  if (header) {
    return (
      <div className={classNames(
        'text-2xl',
        first ? 'flex-[1.5] text-left' : 'flex-1 text-center',
      )}>
        {children}
      </div>
    );
  }
  return (
    <div className={classNames(first ? 'flex-[1.5] text-left' : 'flex-1 text-center')}>
      {children}
    </div>
  );
}

function FeatureMatrix() {
  return (
    <div className='border border-gray-300 bg-white rounded-lg px-2 pt-4 pb-4'>
      <div className='flex flex-row'>
        <FeatureCell header first/>
        <FeatureCell header>Free</FeatureCell>
        <FeatureCell header>Basic</FeatureCell>
        <FeatureCell header>Enterprise</FeatureCell>
      </div>

      {/* Storage */}
      <FeatureSection>
        Data Storage
      </FeatureSection>
      <FeatureRow>
        <FeatureCell first>
          Number of Definitions
        </FeatureCell>
        <FeatureCell>
          3
        </FeatureCell>
        <FeatureCell>
          20
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Objects per Definition
        </FeatureCell>
        <FeatureCell>
          500
        </FeatureCell>
        <FeatureCell>
          50,000
        </FeatureCell>
        <FeatureCell>
          Negotiable
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Attachment Storage
        </FeatureCell>
        <FeatureCell>
          1GB
        </FeatureCell>
        <FeatureCell>
          20GB
        </FeatureCell>
        <FeatureCell>
          Negotiable
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Relationships
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Formulas
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Table Indexes
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>

      {/* Forms */}
      <FeatureSection>
        Forms
      </FeatureSection>
      <FeatureRow>
        <FeatureCell first>
          Number of Forms
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Basic Widgets
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Conditional Sections
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Computed Fields
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Hidden Fields
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Pre-filled Fields
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Collection Widgets
        </FeatureCell>
        <FeatureCell>
          List Only
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Advanced Widgets
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Pivot Tables and Charts
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>

      {/* Mechanisms and Automation */}
      <FeatureSection>
        Mechanisms and Automation
      </FeatureSection>
      <FeatureRow>
        <FeatureCell first>
          Number of Mechanisms
        </FeatureCell>
        <FeatureCell>
          10
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
        <FeatureCell>
          Unlimited
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Runs per month
        </FeatureCell>
        <FeatureCell>
          500
        </FeatureCell>
        <FeatureCell>
          25,000
        </FeatureCell>
        <FeatureCell>
          Negotiable
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Form-Triggered Mechanisms
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>

      {/* Workflow and Automation */}
      <FeatureSection>
        Form Deployment
      </FeatureSection>
      <FeatureRow>
        <FeatureCell first>
          Standalone Pages
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          HTML Embed
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Internal Applications
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>

      {/* Branding */}
      <FeatureSection>
        Branding
      </FeatureSection>
      <FeatureRow>
        <FeatureCell first>
          Custom Styling
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
      <FeatureRow>
        <FeatureCell first>
          Remove Mechaform branding
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          {/* Blank */}
        </FeatureCell>
        <FeatureCell>
          <FeatureCheck/>
        </FeatureCell>
      </FeatureRow>
    </div>
  );
}

type BillingDurationProps = {
  annual: boolean;
  setAnnual(annual: boolean): void;
};

function BillingDuration({ annual, setAnnual }: BillingDurationProps) {
  const setToMonthly = React.useCallback(() => {
    setAnnual(false);
  }, [setAnnual]);

  const setToYearly = React.useCallback(() => {
    setAnnual(true);
  }, [setAnnual]);

  return (
    <div className='rounded-full bg-white border border-purple-400 py-[2px] px-[3px] w-[200px]'>
      <div className={'flex flex-gap-4 items-center relative'}>
        <div className={classNames(
          'bg-purple-400 rounded-full absolute z-0',
          Styles.billing,
          annual ? Styles.yearly : Styles.monthly,
        )}/>
        <button
          type='button'
          onClick={setToMonthly}
          className={classNames(
            'flex-1 text-center bg-transparent relative p-[2px]',
            Styles.foreground,
            !annual ? 'text-white' : 'text-gray-900',
          )}
        >
          Monthly
        </button>
        <button
          type='button'
          onClick={setToYearly}
          className={classNames(
            'flex-1 text-center bg-transparent relative p-[2px]',
            Styles.foreground,
            annual ? 'text-white' : 'text-gray-900',
          )}
        >
          Yearly
        </button>
      </div>
    </div>
  );
}

export const PricingComponent = () => {
  const [annual, setAnnual] = React.useState(true);

  return (
    <div className={classNames('flex flex-col items-stretch min-h-full relative', HexStyles.hexfixed)}>
      <Header/>
      <div className='flex-1 py-[60px] px-4'>
        <h1 className='text-center text-gray-800 text-4xl font-bold mb-2'>
          Mechaform Pricing
        </h1>
        <p className='text-center text-gray-700 text-lg font-bold mb-12'>
          Choose the plan that works for you
        </p>
        <div className='flex justify-center mb-8'>
          <BillingDuration annual={annual} setAnnual={setAnnual}/>
        </div>
        <div className='max-w-screen-lg mx-auto mt-8 mb-16'>
          <PricingTable annual={annual}/>
        </div>
        <h2 className='text-center text-gray-700 text-2xl font-bold mb-16'>
          Organize your data. All in one place.
        </h2>
        <h1 className='text-center text-gray-800 text-4xl font-bold mb-2 hidden lg:block'>
          Compare our plans
        </h1>

        <div className='max-w-screen-lg mx-auto mt-8 mb-16 hidden lg:block'>
          <FeatureMatrix/>
        </div>

        <ConceptsCallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
