import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export function useAnalyticsTracker() {
  const { pathname } = useLocation();

  // Send pageview to Google Analytics whenever pathname changes
  useEffect(() => {
    trackEvent('event', 'page_view', {
      page_title: document.title,
      page_location: pathname,
    });
  }, [pathname]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && target.hasAttribute('data-track')) {
        const attribute = target.getAttribute('data-track') ?? '';
        const target_id = attribute || target.id || 'unknown';

        trackEvent('event', 'button_click', {
          page_title: document.title,
          page_location: pathname,
          event_target: target_id,
          label: target.innerText,
        });
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [pathname]);
}

function trackEvent(
  command: keyof Gtag.GtagCommands,
  eventName: Gtag.EventNames | string,
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined,
) {
  return window.gtag(command, eventName, eventParams);
}
